<template>
  <div class="box">
    <van-sticky :offset-top="0">
      <van-search
        v-model="search.name"
        shape="round"
        @focus="toSearch"
        placeholder="输入您要搜索的产品名，如：微业贷"
      />
      <van-row type="flex" justify="space-around" class="select-box">
        <van-col span="6"
          ><div class="select" @click="addressShow = true">
            {{ cityText }}<van-icon class="iconfont icon-xiala icont" /></div
        ></van-col>
        <van-col span="6"
          ><div
            class="select"
            :class="search.sort == 'max_quota,desc' ? 'blue' : ''"
            @click="sort('max_quota,desc')"
          >
            贷款额度<van-icon class="iconfont icon-jiangxu icont" /></div
        ></van-col>
        <van-col span="6"
          ><div
            class="select"
            :class="search.sort == 'rate,asc' ? 'blue' : ''"
            @click="sort('rate,asc')"
          >
            综合利率<van-icon class="iconfont icon-shengxu icont" /></div
        ></van-col>
        <van-col span="6"
          ><div class="select" @click="screenShow = true">
            筛选<van-icon class="iconfont icon-a-sx3x icont" /></div
        ></van-col>
      </van-row>
    </van-sticky>
    <van-popup
      v-model="addressShow"
      closeable
      round
      position="bottom"
      :style="{ height: '70%' }"
    >
      <div class="address-box">
        <div class="address-title">当前定位城市</div>
        <div class="location-box">
          <div class="location-name">
            {{ cityText }}<van-icon class="iconfont icon-Frame1 icont" />
          </div>
          <div class="location-button" @click="getCity">重新定位</div>
        </div>
        <van-cascader
          v-model="search.cityCodes"
          title="请选择所在地区"
          :closeable="false"
          :options="cityList"
          :field-names="fieldNames"
          @finish="cityOK"
          class="city-select"
        />
      </div>
    </van-popup>
    <van-popup
      v-model="screenShow"
      closeable
      round
      position="bottom"
      :style="{ height: '60%' }"
    >
      <div class="screen-box">
        <div class="screen-title">筛选</div>
        <div class="tag-title">贷款品种</div>
        <div class="tag-box">
          <div
            class="tag"
            :class="item.active ? 'active' : ''"
            v-for="item in loan_varieties_type"
            :key="item.id"
            @click="varietiesChange(item)"
          >
            {{ item.label }}
          </div>
        </div>
        <div class="tag-title">还款方式</div>
        <div class="tag-box">
          <div
            class="tag"
            :class="item.active ? 'active' : ''"
            v-for="item in repayment"
            :key="item.id"
            @click="repaymentChange(item)"
          >
            {{ item.label }}
          </div>
        </div>
        <div class="button-box">
          <div @click="reset">重置</div>
          <div style="color: #ffffff; background: #1377ff" @click="sub">
            完成
          </div>
        </div>
      </div>
    </van-popup>
    <van-list
      v-model="loading"
      :finished="finished"
      offset="100"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div class="product-box">
        <div
          class="product"
          v-for="item in list"
          :key="item.productId"
          @click="toInfo(item.productId)"
        >
          <div class="product-top">
            <div style="display: flex; align-items: center">
              <van-icon
                :name="
                  'https://img.weiqilile.com/bank/thumb/' +
                  item.institutionCode +
                  '.png'
                "
                size="20px"
                style="margin-right: 4px"
              />
              {{ item.name }}
            </div>
            <!-- <div class="product-tag">
              办理方式：{{
                item.method == 0
                  ? "线上+线下办理"
                  : item.method == 1
                  ? "线下办理"
                  : "线上办理"
              }}
            </div> -->
          </div>
          <van-row>
            <van-col span="8">
              <div class="price">{{ item.maxQuota }}万</div>
              <div class="product-name">最高额度</div>
            </van-col>
            <van-col span="8">
              <!-- <div class="rate">{{ item.lixi }}%</div>
              <div class="product-name">{{ item.lixiWenan }}</div> -->
              <div class="rate">{{ item.rate }}%</div>
              <div class="product-name">年化利率</div>
            </van-col>
            <van-col span="8">
              <div class="mode">{{ item.fangshi }}</div>
              <div class="product-name">还款方式</div></van-col
            >
          </van-row>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import Vue from "vue";
import { Dialog } from "vant";
Vue.use(Dialog);
import AMap from "AMap";
import url from "@/service/url-config";
export default {
  data() {
    return {
      repayment: [], //产品还款方式
      loan_varieties_type: [], //产品贷款品种
      list: [],
      page: 0,
      size: 10,
      loading: true,
      finished: false,
      noData: require("@/img/no-data.png"),
      search: {
        name: "",
        sort: "",
        repayment: "",
        loan_varieties_type: "",
        cityCodes: "410100",
      },
      addressShow: false,
      cityList: [],
      fieldNames: {
        text: "name",
        value: "id",
        children: "children",
      },
      cityText: "郑州市",
      screenShow: false,
    };
  },
  created() {
    this.search.name = this.$route.query.search ? this.$route.query.search : "";
    this.getDict();
    // this.getCity();
    this.$http.get(url.address).then((res) => {
      this.cityList = res.data;
    });
  },
  methods: {
    //字典
    getDict() {
      this.$http.get(url.dict).then((res) => {
        res.data.forEach((v) => {
          this[v.name] = v.dictDetails;
        });
        this.getCity();
      });
    },
    //全部产品
    getList() {
      let { name, sort, repayment, loan_varieties_type, cityCodes } =
        this.search;
      this.$http
        .get(
          url.getProductList +
            `?page=${this.page}&size=${this.size}&sort=${sort}&name=${name}&repayment=${repayment}&loanVarieties=${loan_varieties_type}&cityCodes=${cityCodes}`
        )
        .then((res) => {
          this.loading = false;
          let zidian = {};
          this.repayment.forEach((v) => {
            zidian[v.value] = v.label;
          });
          // let zidian = {
          //   0: "等额本金",
          //   1: "等额本息",
          //   2: "先息后本",
          //   3: "等本等息",
          // };
          res.data.content.forEach((v) => {
            // if (v.rateClerk && v.rateClerk.length > 0) {
            //   v.lixiWenan = v.rateClerk.split(/\s+/)[0];
            //   v.lixi = v.rateClerk.split(/\s+/)[1];
            // }
            if (v.repayment && v.repayment.length > 0) {
              v.fangshi = "";
              let arr = v.repayment.split(",");
              arr.forEach((a) => {
                v.fangshi += zidian[a] + " ";
              });
            }
          });
          if (res.data.content.length < this.size) {
            this.finished = true;
          }
          this.loading = false;
          this.list = this.list.concat(res.data.content);
        });
    },
    //加载更多
    onLoad() {
      this.page = this.page + 1;
      this.getList();
    },
    //搜索
    toSearch() {
      this.$router.push("search");
    },
    //排序
    sort(val) {
      this.page = 0;
      this.list = [];
      this.finished = false;
      this.loading = true;
      this.search.sort = val;
      this.getList();
    },
    //选择品牌
    varietiesChange(item) {
      this.$set(item, "active", !item.active);
    },
    //选择还款方式
    repaymentChange(item) {
      this.$set(item, "active", !item.active);
    },
    //完成
    sub() {
      this.list = [];
      this.page = 0;
      this.finished = false;
      this.loading = true;
      this.search.repayment = "";
      this.search.loan_varieties_type = "";
      this.loan_varieties_type.forEach((v) => {
        if (v.active) {
          this.search.loan_varieties_type =
            this.search.loan_varieties_type + v.value + ",";
        }
      });
      this.repayment.forEach((v) => {
        if (v.active) {
          this.search.repayment = this.search.repayment + v.value + ",";
        }
      });
      this.getList();
      this.screenShow = false;
    },
    //重置
    reset() {
      this.loan_varieties_type.forEach((v) => {
        // v.active = false;
        this.$set(v, "active", false);
      });
      this.repayment.forEach((v) => {
        // v.active = false;
        this.$set(v, "active", false);
      });
      this.search.repayment = "";
      this.search.loan_varieties_type = "";
      // this.screenShow = false;
    },
    //获取定位信息
    getCity() {
      let this_ = this;
      AMap.plugin("AMap.CitySearch", function () {
        var citySearch = new AMap.CitySearch();
        citySearch.getLocalCity((status, result) => {
          // console.log(result);
          if (status === "complete" && result.info === "OK") {
            // 查询成功，result即为当前所在城市信息
            if (result.adcode.length > 0) {
              this_.cityText = result.city;
              this_.search.cityCodes = result.adcode;
            } else {
              this_.cityText = "郑州";
              this_.search.cityCodes = 410100;
            }
            this_.list = [];
            this_.page = 0;
            this_.finished = false;
            this_.loading = true;
            this_.getList();
          } else {
            this_.cityText = "郑州市";
            this_.search.cityCodes = 410100;
            this_.list = [];
            this_.page = 0;
            this_.finished = false;
            this_.loading = true;
            this_.getList();
          }
        });
      });
    },
    //地址选完
    cityOK({ selectedOptions }) {
      this.cityText = selectedOptions[1].name;
      this.addressShow = false;
      this.list = [];
      this.page = 0;
      this.finished = false;
      this.getList();
    },
    //产品详情
    toInfo(id) {
      this.$router.push({
        path: "productInfo",
        query: {
          id: id,
          type: "putong",
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background: #f5f5f5;
  min-height: calc(100vh - 80px);
  padding-bottom: 80px;
  .address-box {
    height: calc(100% - 40px);
    padding-top: 40px;
    .address-title {
      width: 345px;
      margin: 0 auto;
      margin-bottom: 10px;
      color: #999999;
      font-size: 14px;
    }
    .location-box {
      width: 345px;
      margin: 0 auto;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      .location-name {
        color: #333333;
        font-size: 16px;
      }
      .location-button {
        font-size: 14px;
        color: #1377ff;
      }
    }
  }
  .address-box /deep/.van-cascader__options {
    height: 250px;
  }
  .select-box {
    height: 52px;
    line-height: 52px;
    background: #ffffff;
    margin-bottom: 10px;
    .select {
      font-size: 14px;
      color: #888888;
      text-align: center;
    }
    .blue {
      color: #1377ff;
    }
  }
  .screen-box {
    position: relative;
    height: 100%;
    .screen-title {
      width: 100%;
      text-align: center;
      color: #333333;
      font-size: 17px;
      font-weight: bold;
      padding-top: 15px;
    }
    .tag-title {
      width: 325px;
      margin: 0 auto;
      color: #333333;
      font-size: 15px;
      font-weight: bold;
    }
    .tag-box {
      width: 325px;
      margin: 0 auto;
      margin-top: 15px;
      display: flex;
      flex-wrap: wrap;
      .tag {
        padding: 6px 24px;
        background: #f8f8f8;
        border-radius: 2px;
        color: #555555;
        font-size: 12px;
        margin-right: 10px;
        margin-bottom: 12px;
        border: 1px solid #f8f8f8;
      }
      .active {
        background: #ecf4ff;
        color: #1377ff;
        border: 1px solid #1377ff;
      }
    }
    .button-box {
      display: flex;
      position: absolute;
      bottom: 60px;
      left: 50%;
      margin-left: -152px;
      div {
        width: 152px;
        height: 40px;
        border: 1px solid #1377ff;
        color: #666666;
        font-size: 14px;
        text-align: center;
        line-height: 40px;
      }
    }
  }
  .tabs /deep/ .van-sticky {
    height: 50px;
    background: #ffffff;
  }
  .product-box {
    width: 355px;
    margin: 0 auto;
    margin-bottom: 15px;
    border-radius: 5px;
  }
  .product {
    width: calc(355px - 20px);
    height: calc(140px - 34px);
    // border-bottom: 1px solid #f3f3f3;
    padding: 17px 10px;
    background: #ffffff;
    border-radius: 10px;
    margin-bottom: 8px;
    overflow: hidden;
  }
  .product-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 14px;
    font-size: 12px;
    font-weight: bold;
    color: #405080;
  }
  .product-tag {
    font-size: 10px;
    color: #f26659;
    background: #fdeeec;
    padding: 2px 5px;
    border-radius: 4px;
  }
  .price {
    font-size: 23px;
    color: #f55c00;
    font-weight: bold;
    width: 100%;
    text-align: center;
    padding-bottom: 2px;
  }
  .rate {
    font-size: 23px;
    color: #f55c00;
    font-weight: bold;
    width: 100%;
    text-align: center;
    padding-top: 4px;
    // padding-bottom: 5px;
  }
  .mode {
    font-size: 12px;
    font-weight: bold;
    color: #f55c00;
    width: 100%;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-bottom: 10px;
    padding-top: 6px;
  }
  .product-name {
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #808aab;
  }

  .list-title {
    height: 42px;
    color: #555555;
    font-size: 12px;
    line-height: 42px;
    text-align: center;
    border-bottom: 1px solid #ededed;
  }
  .list {
    height: 42px;
    color: #555555;
    font-size: 12px;
    line-height: 42px;
    text-align: center;
    border-bottom: 1px solid #ededed;
  }
  .list-name {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .red {
    color: #ef5446;
  }
  .blue {
    color: #1377ff;
  }

  .noMeasurement {
    width: 100%;
    text-align: center;
    margin-top: 73px;
  }
  .noMeasurement img {
    width: 120px;
    height: 113px;
  }
  .measurement-hint {
    font-size: 15px;
    color: #666666;
    margin-top: 26px;
  }
  .toMeasurement {
    width: 220px;
    height: 42px;
    background: #0084ff;
    border-radius: 10px;
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    line-height: 42px;
    margin: 0 auto;
    margin-top: 80px;
  }
}
</style>
